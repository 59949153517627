var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',[_c('div',{staticClass:"o-survey-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"click:row":function (item) { return _vm.surveyDetails(item); }},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getSurveyType(item.type)))]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [(item.isEnabled)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.url))])]}},{key:"item.participants",fn:function(ref){
var item = ref.item;
return [(item.type === 'personal')?_c('span',[_vm._v(_vm._s(item.participants.length))]):_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.notApplicable')))])]}},{key:"item.questions",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.questions.length))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.questions && item.questions.length < 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","to":{ name: 'panel.event.view.surveyModule.question', params: { smid: item.id }, query: { name: item.name } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.modules.surveyModule.addQuestion')))])]):_vm._e(),_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[(item.questions && item.questions.length < 1)?_c('v-list-item',{attrs:{"to":{ name: 'panel.event.view.surveyModule.question', params: { smid: item.id }, query: { name: item.name } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('event.panel.modules.surveyModule.addQuestion')))])],1):_vm._e(),_c('v-list-item',{attrs:{"to":{ name: 'panel.event.view.surveyModule.edit', params: { smid: item.id } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('event.panel.modules.surveyModule.editSurvey')))])],1),(item.questions && item.questions.length >= 1)?_c('v-list-item',{attrs:{"to":{ name: 'panel.event.view.surveyModule.question.edit', params: { smid: item.id }, query: { name: item.name } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('event.panel.modules.surveyModule.editQuestions')))])],1):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-list-item',_vm._g({},Object.assign({}, dialog)),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)]}}],null,true),model:{value:(item.dialog),callback:function ($$v) {_vm.$set(item, "dialog", $$v)},expression:"item.dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"red","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){item.dialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))])],1)],1)],1),(item.type === 'anonymous')?_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.copyLink(item.id, item.url)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('event.panel.config.discounts.list.copyLink')))])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.copyVariable(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('event.panel.config.discounts.list.copyVariable')))])],1)],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.state.surveyDetails),callback:function ($$v) {_vm.$set(_vm.state, "surveyDetails", $$v)},expression:"state.surveyDetails"}},[_c('m-survey-details',{attrs:{"item":_vm.state.surveyDetailsItem},on:{"close-dialog":function($event){_vm.state.surveyDetails = false}}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }