















































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MSurveyDetails: () =>
      import("@/components/molecules/surveys/m-survey-details.vue"),
  },
  setup(_, { root }) {
    const { getSurveyType } = useMisc({ root });

    const state = reactive({
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "name", text: root.$tc("layout.misc.name") },
        { value: "type", text: root.$tc("layout.misc.type") },
        { value: "url", text: root.$tc("layout.misc.link") },
        {
          value: "participants",
          text: root.$tc("layout.misc.assignedParticipants"),
          align: "right",
        },
        {
          value: "questions",
          text: root.$tc("layout.misc.questionsQuantity"),
          align: "right",
          width: 1,
        },
        {
          value: "isEnabled",
          text: root.$tc("layout.misc.state"),
          align: "right",
          width: 1,
        },
        { text: "", value: "actions", align: "right" },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      surveyDetails: false,
      surveyDetailsItem: [],
    });

    const surveyDetails = (item: any) => {
      state.surveyDetailsItem = Object.assign({}, item);
      state.surveyDetails = true;
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/survey`, {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
          },
        })
        .then(({ data: { surveys, total } }) => {
          state.empty = false;
          state.items = surveys;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`survey/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const copyVariable = (id: string) => {
      navigator.clipboard.writeText(`{{{ survey:${id} }}}`);
      root.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "primary",
        message: root.$tc("event.panel.config.discounts.list.variableCopied"),
      });
    };

    const copyLink = (id: string, url: string) => {
      const link = url.split("#")[0];
      navigator.clipboard.writeText(`${link}/?surveyId=${id}`);
      root.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "primary",
        message: root.$tc("layout.misc.linkCopied"),
      });
    };

    return {
      state,
      fetchData,
      deleteItem,
      getSurveyType,
      surveyDetails,
      copyVariable,
      copyLink,
    };
  },
});
